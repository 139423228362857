/* Simpler scrollbar for windows */
::-webkit-scrollbar {
  width: 6px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

html {
  height: 100%;
  font-size: 16px;
  overflow: auto;
}

body {
  height: 100%;
  font-family: "RelishPro", sans-serif;
}

a:focus-visible,
button:focus-visible,
/* input:focus-visible, */
[type='radio']:focus-visible {
  outline: 3px solid #00aaff;
}

body input:-ms-input-placeholder,
body textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

body input::-ms-input-placeholder,
body textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}

body input::placeholder,
body textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.active {
  color: #004bee;
  background-color: white;
  display: block;
  padding: 15px;
  font-weight: 600;
  border-radius: 8px;
}
